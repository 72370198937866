import React, { Suspense } from "react";
import { NavLink } from "react-router-dom";
import SessionForm from "./../Elements/SessionForm";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Collapsible from "react-collapsible";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import ContactForm from "./ContactFormFooter";
//config url
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";


const Footer = ({ loginRedirection }) => {
  const { register, handleSubmit } = useForm();

  const submitForm = async (data) => {
    try {
      const response = await fetch(`${configData.ADMIN_URL}/api/newsletter`, {
        method: "POST",
        body: JSON.stringify({
          email: data.email,
        }),
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const result = await response.json();

        toast.error(result.message, { autoClose: 3000 });
      }
      const result = await response.json();

      if (result.data.success) {
        toast.success("Subscription Successful", { autoClose: 3000 });
        document.newsletter.reset();
      }
      console.log(result);
    } catch (err) {
      console.log(err);
      toast.error(err, { autoClose: 3000 });
    }
  };
  const handleError = (errors) => {
    toast.error("Email field must be filled", { autoClose: 3000 });
  };
  const registerOptions = {
    email: { required: "required" },
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  //contact form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [requirementError, setRequirementError] = useState("");

  const userInfo = useContext(IPInfoContext);

  // const enabled =
  //   requirement.length > 0 &&
  //   name.length > 0 &&
  //   email.length > 0 &&
  //   mobile.length > 0;

   useEffect(() => {
     const fetchCountryData = async () => {
       setIp(userInfo.ip);
       if (ip) {
         try {
           const response = await fetch(`https://api.country.is/${ip}`);
           const data = await response.json();
           setCountryInfo(data.country);
         } catch (error) {
           console.error("Error fetching country data:", error);
         }
       }
     };
     fetchCountryData();
   }, [userInfo.ip, ip]);
   const handleChangeNumber = (number) => {
     setNumber(number);
     if (number) {
       try {
         const phoneNumber = parsePhoneNumberFromString(number);

         const countryCode = `${
           phoneNumber ? phoneNumber.countryCallingCode : ""
         }`;
         const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
         setCode(countryCode);
         // setValue(nationalNumber);
         setMobile(code + nationalNumber);

         if (isValidPhoneNumber(number)) {
           console.log(number);
           setError(false);
         } else {
           setError(true);
         }
       } catch (error) {
         setError(true);
       }
     } else {
       setCode("");
       // setValue("");
       setError(true);
     }
   };
   const handleNameChange = (e) => {
     const value = e.target.value;
     setName(value);
     setNameError(value.trim() === "" ? "Name is required" : "");
   };

   const handleEmailChange = (e) => {
     const value = e.target.value;
     setEmail(value);
     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     setEmailError(!emailRegex.test(value) ? "Please enter a valid email" : "");
   };

   const handleRequirementChange = (e) => {
     const value = e.target.value;
     setRequirement(value);
     setRequirementError(value.trim() === "" ? "Requirement is required" : "");
   };

  async function saveQuery(event) {
    event.preventDefault();
    if (name && email && mobile && requirement) {
      let data = { name, email, mobile, requirement };
      if (isValidPhoneNumber("+" + mobile)) {
        let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            Accept: "applicatiion/json",
          },

          body: JSON.stringify(data),
        });

        const item = await result.json();
        if (item.status === "success") {
          window.location.href = `/thanks.php`;
          setIsOpen(false);
        } else {
          toast.error("Please Try Again!", { autoClose: 3000 });
        }
      } else {
        toast.error("Invalid Phone Number!", { autoClose: 3000 });
      }
    } else {
      if (!mobile) {
        setError(true);
      }
      if (!name) {
        setNameError("Name is required");
      }
      if (!email) {
        setEmailError("Email is required");
      }
      if (!requirement) {
        setRequirementError("Requirement is required");
      }
    }
  }

  return (
    <>
      <footer
        className="site-footer footer-large  footer-dark	footer-wide"
        style={{ height: "auto" }}
      >
        {/* FOOTER BLOCKES START */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* ABOUT COMPANY */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="widget widget_about">
                  {/*<h4 class="widget-title">About Company</h4>*/}
                  <div className="logo-footer clearfix p-b15">
                    <h5
                      className="widget-title mobile-hidden"
                      style={{ fontWeight: 600 }}
                    >
                      Quick Contact
                    </h5>
                    <h5
                      className="widget-title desktop-hidden"
                      style={{ fontWeight: 600, fontSize: "1rem" }}
                    >
                      Quick Contact
                    </h5>
                  </div>
                  <div className="call-to-action p-t10 p-b10 ">
                    <ContactForm />
                  </div>
                  <p className="mb-1 text-black">Follow us :</p>
                  <ul className="social-icons  sx-social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/NaksheWala.HouseDesign/?ref=bookmarks"
                        className="fa fa-facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/nakshewala/"
                        className="fa fa-instagram"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Nakhshewala"
                        className="fa fa-twitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/+918010822233"
                        className="fa fa-whatsapp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.youtube.com/@nakshewala_"
                        className="fa fa-youtube"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://in.linkedin.com/company/nakshewala"
                        className="fa fa-linkedin"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://in.pinterest.com/nakshewala/"
                        className="fa fa-pinterest"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">test</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://idwinteriors.com/"
                        className="fa fa-globe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="d-none">
                          For End-to-End Interiors In Noida, Gr Noida and
                          Ghaziabad Check Out
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="call-to-action-left mt-3">
                    <h5 className=" m-b10 scribe-text-footer">
                      Subscribe to our newsletter!
                    </h5>
                  </div>
                  <div className="call-to-action-right">
                    <div className="widget_newsletter">
                      <div className="newsletter-bx">
                        <form
                          name="newsletter"
                          role="search"
                          onSubmit={handleSubmit(submitForm, handleError)}
                        >
                          <div className="input-group">
                            <input
                              {...register("email", registerOptions.email)}
                              className="form-control"
                              placeholder="ENTER YOUR EMAIL"
                              type="text"
                              style={{ fontSize: 13 }}
                            />
                            <span className="input-group-btn">
                              <button type="submit" className="site-button">
                                Subscribe
                              </button>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <br />
                  <ul className="social-icons  sx-social-links">
                    <li>
                      <a
                        href={`${configData.IDW_URL}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="">
                          For End-to-End Interiors In Noida, Gr Noida and
                          Ghaziabad Check Out: idwinteriors.com
                        </span>
                      </a>
                    </li>
                  </ul>
                  {/* <div className="call-to-action-left mt-3">
                      <a href="https:/idwinteriors.com" target="_blank" rel="noreferrer">
                        For End-to-End Interiors In Noida, Gr Noida and Ghaziabad
                        Check Out: idwinteriors.com
                      </a>
                  </div> */}
                </div>
              </div>
              {/* RESENT POST */}
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                <div className="widget widget_services inline-links">
                  <div className="desktop-hidden">
                    <Collapsible
                      open="true"
                      className="widget-title widget-mobile"
                      trigger="Services"
                    >
                      <ul>
                        <li>
                          <NavLink to={"/readymade-house-design.php"}>
                            Readymade House Design
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/commercial-building-elevation.php"}>
                            Commercial Building Elevation
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/interior-designing.php"}>
                            Interior Design
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/front-elevation.php"}>
                            3D Front Elevation
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/customized-house-designs.php"}>
                            Customized House Design
                          </NavLink>
                        </li>
                        <li>
                          <a href="/3d-2d-floor-plans.php">3D Floor Plan</a>
                        </li>
                        <li>
                          <NavLink to={"/vaastu-consultancy.php"}>
                            Vastu Consultancy
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/readymade-floor-plans.php"}>
                            Readymade Floor Plans
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/commercial-designs"}>
                            Commercial Designs
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/video-walkthrough.php"}>
                            Video Walkthrough
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/completedprojects.php"}>
                            Completed Projects
                          </NavLink>
                        </li>
                      </ul>
                    </Collapsible>
                  </div>

                  <h5 className="widget-title mobile-hidden">Services</h5>
                  <ul className="mobile-hidden">
                    <li>
                      <NavLink to={"/readymade-house-design.php"}>
                        Readymade House Design
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/commercial-building-elevation.php"}>
                        Commercial Building Elevation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/interior-designing.php"}>
                        Interior Design
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/front-elevation.php"}>
                        3D Front Elevation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/customized-house-designs.php"}>
                        Customized House Design
                      </NavLink>
                    </li>
                    <li>
                      <a href="/3d-2d-floor-plans.php">3D Floor Plan</a>
                    </li>
                    <li>
                      <NavLink to={"/vaastu-consultancy.php"}>
                        Vastu Consultancy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/readymade-floor-plans.php"}>
                        Readymade Floor Plans
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/commercial-designs"}>
                        Commercial Designs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/video-walkthrough.php"}>
                        Video Walkthrough
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/completedprojects.php"}>
                        Completed Projects
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <hr className="desktop-hidden" />
              </div>
              {/* USEFUL LINKS */}
              <div className="col-lg-2 col-md-2 col-sm-2 footer-col-2">
                <div className="widget widget_services inline-links">
                  <div className="desktop-hidden">
                    <Collapsible
                      className="widget-title widget-mobile"
                      trigger="Company"
                    >
                      <ul>
                        <li>
                          <NavLink to={"/aboutus.php"}>About us</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/contact-us.php"}>Contact us</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/our-clients.php"}>Our Clients</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/blog"}>Blogs</NavLink>
                        </li>
                        <li>
                          <a href="https://www.nakshewala.in/crm/recruitment/recruitment_portal">
                            Career
                          </a>
                        </li>
                        <li>
                          <NavLink to={"/feedback.php"}>Feedback</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/testimonial.php"}>
                            Testimonials
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to={"/site-map.php"}>Sitemap</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/faq"}>Faq</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/our-packages.php"}>
                            Our Packages
                          </NavLink>
                        </li>
                      </ul>
                    </Collapsible>
                  </div>

                  <h5 className="widget-title mobile-hidden">Company</h5>
                  <ul className="mobile-hidden">
                    <li>
                      <NavLink to={"/aboutus.php"}>About us</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/contact-us.php"}>Contact us</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/our-clients.php"}>Our Clients</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/blog"}>Blogs</NavLink>
                    </li>
                    <li>
                      <a href="https://www.nakshewala.in/crm/recruitment/recruitment_portal">
                        Career
                      </a>
                    </li>
                    <li>
                      <NavLink to={"/feedback.php"}>Feedback</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/testimonial.php"}>Testimonials</NavLink>
                    </li>

                    <li>
                      <NavLink to={"/site-map.php"}>Sitemap</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/faq"}>Faq</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/our-packages.php"}>Our Packages</NavLink>
                    </li>
                  </ul>
                </div>
                <hr className="desktop-hidden" />
                <div className="widget widget_services inline-links">
                  <div className="desktop-hidden">
                    <Collapsible
                      className="widget-title widget-mobile"
                      trigger="Legal"
                    >
                      <ul>
                        <li>
                          <NavLink to={"/privacy-policy.php"}>
                            Privacy Policy
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"/terms-and-conditions.php"}>
                            Terms & Conditions
                          </NavLink>
                        </li>
                      </ul>
                    </Collapsible>
                  </div>

                  <h5 className="widget-title mb-2 mobile-hidden">Legal</h5>
                  <ul className="mobile-hidden">
                    <li>
                      <NavLink to={"/privacy-policy.php"}>
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/terms-and-conditions.php"}>
                        Terms & Conditions
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <hr className="desktop-hidden" />
              </div>

              {/* CONTACT US */}
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="widget widget_address_outer">
                  <div className="desktop-hidden">
                    <Collapsible
                      className="widget-title widget-mobile"
                      trigger="Contact Us"
                    >
                      <ul>
                        <li>
                          <i className="fa fa-envelope pr-1"></i>{" "}
                          <a href="mailto:support@nakshewala.com">
                            support@nakshewala.com
                          </a>
                        </li>

                        <li>
                          <i className="fa fa-phone pr-1"></i>{" "}
                          <a href="tel:+918010822233">+91 - 8010822233</a>
                        </li>
                      </ul>
                    </Collapsible>
                  </div>

                  <h5 className="widget-title mb-2 mobile-hidden">
                    Contact Us
                  </h5>
                  <ul className="widget_address mobile-hidden">
                    <li>
                      <i className="fa fa-envelope pr-1"></i>{" "}
                      <a href="mailto:support@nakshewala.com">
                        support@nakshewala.com
                      </a>
                    </li>

                    <li>
                      <i className="fa fa-phone pr-1"></i>{" "}
                      <a href="tel:+918010822233">+91 - 8010822233</a>
                    </li>
                  </ul>
                </div>
                <hr className="desktop-hidden" />

                <div className="desktop-hidden">
                  <h5 className="widget-title mb-2 mt-3">Make Payment</h5>
                  <ul className="widget_address">
                    <li>
                      Scan <b>QR</b> to make quick payments
                    </li>
                    <img src="/assets/images/scanner.webp" alt="scanner" />
                    <li className="mt-2 fs-16">
                      <b>Rated: 4.5/5</b> <i className="fa fa-star"></i>{" "}
                      <i className="fa fa-star"></i>{" "}
                      <i className="fa fa-star"></i>{" "}
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                  <h5 className="widget-title mb-2">Our Payment Partners</h5>
                  <ul className="widget_address">
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://paypal.me/nakshewala"
                      >
                        <img
                          src="/assets/images/paypal-2.svg"
                          alt="Paypal"
                          style={{ paddingRight: 20 }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://pmny.in/PAYUMN/9IylroWKarUi"
                      >
                        <img
                          src="/assets/images/payu.svg"
                          alt="payu"
                          style={{ paddingLeft: 20 }}
                        />
                      </a>
                    </div>
                  </ul>

                  <div style={{ marginTop: 10 }}>
                    <img src="/assets/images/NoPath.svg" alt="scanner" />
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/images/certified.svg"
                      alt="scanner"
                    />
                  </div>
                </div>

                <div className="widget widget_address_outer mobile-hidden">
                  <h5 className="widget-title mb-2">Our Payment Partners</h5>
                  <ul className="widget_address">
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://paypal.me/nakshewala"
                      >
                        <img
                          src="/assets/images/paypal-2.svg"
                          alt=""
                          style={{ paddingRight: 20 }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://pmny.in/PAYUMN/9IylroWKarUi"
                      >
                        <img
                          src="/assets/images/payu.svg"
                          alt=""
                          style={{ paddingLeft: 20 }}
                        />
                      </a>
                    </div>

                    <h5 className="widget-title mb-2 mt-3">Make Payment</h5>
                    <ul className="widget_address">
                      <li>Scan QR to make quick payments</li>
                      <div>
                        <img
                          src="/assets/images/scanner.webp"
                          alt=""
                          style={{ height: "100%", width: "30%" }}
                        />

                        <img
                          src="/assets/images/NoPath.svg"
                          alt=""
                          style={{ paddingRight: 20, paddingLeft: 20 }}
                        />

                        <LazyLoadImage
                          effect="blur"
                          src="/assets/images/certified.svg"
                          alt="scanner"
                        />

                        <li className="mt-2 fs-16">
                          <b>Rated: 4.5/5</b> <i className="fa fa-star"></i>
                        </li>
                      </div>
                    </ul>
                  </ul>
                </div>
                <hr className="desktop-hidden" />
              </div>

              {/* <div className="col-lg-3 col-md-6 col-sm-6 mt-35">
                <div className="widget widget_address_outer mobile-hidden">
                  <div style={{ marginTop: -80 }}>
                    <img
                      src="/assets/images/NoPath.svg"
                      alt=""
                      style={{ paddingRight: 20 }}
                    />
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/images/certified.svg"
                      alt="scanner"
                    />
                  </div>
                  <li className="mt-2 fs-16">
                    <b>Rated: 4.5/5</b> <i className="fa fa-star"></i>
                  </li>
                </div>
</div>*/}
              <hr className="desktop-hidden" />
            </div>
          </div>
          <div className="container">
            <div className="call-to-action-wrap">
              <div className="row">
                <div className="col-lg-7 col-md-6"></div>
                <div className="col-lg-5 col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER COPYRIGHT */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              <div className="sx-footer-bot-left">
                <span className="copyrights-text">
                  © Copyrights 2012-2023 by <a href="/">NaksheWala.com</a> |
                  Designed & Developed by{" "}
                  <a
                    href="https://webnoo.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WEBNOO
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Suspense fallback={<div>Loading....</div>}>
        <div class="footer-fixed">
          <footer class="red lighten-2">
            <nav class="z-depth-0">
              <div class="nav-wrapper">
                <ul class="justify">
                  <li>
                    <NavLink to={"/"}>
                      <i class="fa fa-home"></i>
                      <p className="mb-0 fs-15">Home</p>
                    </NavLink>
                  </li>
                  <li onClick={showModal}>
                    <i class="fa fa-envelope"></i>
                    <p className="mb-0 fs-15">Free Quote</p>
                  </li>
                  <li>
                    <a href=" https://wa.me/+918010822233">
                      <LazyLoadImage
                        effect="blur"
                        alt="+918010822233"
                        src="/assets/images/d7c268ef-298b-45ea-a57f-5877efc6942c.png"
                      />

                      <p className="mb-0 fs-15">Whatsapp</p>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+918010822233">
                      <LazyLoadImage
                        effect="blur"
                        alt="+918010822233"
                        src="/assets/images/66375d40-6114-4a51-9d13-c1ca45f9927e.png"
                      />

                      <p className="mb-1 fs-15">Call us</p>
                    </a>
                  </li>
                </ul>
                <Modal show={isOpen} onHide={hideModal}>
                  <Modal.Header>
                    <div className="modal-header-img"></div>
                    <p className="formText text-center">
                      Discuss with Expert Now
                    </p>
                    <button
                      onClick={hideModal}
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </Modal.Header>

                  <Modal.Body>
                    <form method="post" className="text-center">
                      <div class="result"></div>
                      <div class="form-group">
                        <input
                          onChange={handleNameChange}
                          type="text"
                          className="form-input"
                          placeholder="Enter Name"
                        />
                        {nameError && <p className="error-msg">{nameError}</p>}
                      </div>

                      <div class="form-group">
                        <input
                          onChange={handleEmailChange}
                          required=""
                          type="email"
                          className="form-input"
                          placeholder="Enter Email"
                        />
                        {emailError && (
                          <p className="error-msg">{emailError}</p>
                        )}
                      </div>

                      <div class="form-group">
                        <div className="custom-phone-input">
                          <PhoneInput
                            international
                            className="session-phone-control"
                            defaultCountry={countryInfo}
                            countryCallingCodeEditable={false}
                            value={number || ""} // Ensuring value is a string
                            placeholder="Enter phone number"
                            onChange={handleChangeNumber}
                          />
                        </div>
                        {error && (
                          <p className="errorMsg">
                            Please enter a valid number and country code
                          </p>
                        )}
                      </div>

                      <div class=" form-group">
                        <textarea
                          onChange={handleRequirementChange}
                          required=""
                          className="form-textarea"
                          placeholder="Enter Plot Size & Requirements"
                        ></textarea>
                        {requirementError && (
                          <p className="error-msg">{requirementError}</p>
                        )}
                      </div>

                      <button
                        onClick={saveQuery}
                        //disabled={!enabled}
                        className="submitBtn"
                        type="submit"
                      >
                        Get Free Quote
                      </button>
                    </form>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </nav>
          </footer>
        </div>
        <SessionForm />
      </Suspense>
      {loginRedirection ? (
        <NavLink to={"/login"}>
          <ToastContainer />
        </NavLink>
      ) : (
        <ToastContainer />
      )}

      {/*<Switcher />*/}
    </>
  );
};

export default Footer;
